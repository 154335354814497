import React, { Component } from "react"
import AppStore from "../services/AppStore"
import { Link } from "gatsby"
import DisplayService from "../services/DisplayService"
import MediaService from "../services/MediaService"
import CalendarService from "../services/CalendarService"
import "./calendar-year.css"
import cameraLogo from "../images/camera.png"
import videoLogo from "../images/video.png"
import loadingIcon from "../images/loader.svg"

class CalendarYear extends Component {
  constructor(props) {
    super()

    this.toggleYear = this.toggleYear.bind(this)

    this.state = {
      opened: false,
      loaded: false,
      days: AppStore.get(`CALENDAR-DAYS-${props.data.year}`)
        ? this.getDayList(AppStore.get(`CALENDAR-DAYS-${props.data.year}`))
        : [],
    }
  }

  toggleYear() {
    if (!this.state.loaded) this.loadData()

    AppStore.set(
      `CALENDAR-DAYS-OPEN-${this.props.data.year}`,
      !this.state.opened
    )
    this.setState({
      opened: !this.state.opened,
    })
  }

  getDayList(daysData) {
    this.buckets = []
    if (this.props) {
      try {
        this.buckets = JSON.parse(this.props.bucketSelection)
      } catch (e) {
        console.error(e)
      }
    }
    return CalendarService.getDayList(daysData, this.buckets)
  }

  async loadData() {
    const days = await MediaService.getYearDays(this.props.data.year)

    this.setState({
      loaded: true,
      days: this.getDayList(days),
    })
  }

  componentDidMount() {
    let days = AppStore.get(`CALENDAR-DAYS-${this.props.data.year}`)
    if (!days) return

    this.setState({
      loaded: true,
      opened: AppStore.get(`CALENDAR-DAYS-OPEN-${this.props.data.year}`)
        ? true
        : false,
      days: this.getDayList(days),
    })
  }

  componentDidUpdate(prevState) {
    let days = AppStore.get(`CALENDAR-DAYS-${this.props.data.year}`)
    days = this.getDayList(days)
    if (JSON.stringify(this.state.days) !== JSON.stringify(days)) {
      this.setState({ days })
    }
  }

  render() {
    let content = ""

    if (!this.state.loaded && this.state.opened) {
      content = (
        <div className="loader">
          <img src={loadingIcon} alt="loading" />
        </div>
      )
    }

    if (this.state.loaded && this.state.opened) {
      let data = []
      let currMonth = null
      this.state.days.forEach((dayData, index) => {
        currMonth = +dayData.day.substr(4, 2)
        if (data.filter(d => d.month === currMonth).length) {
          data = data.map(d => {
            if (d.month === currMonth) {
              return {
                month: currMonth,
                items: d.items.concat([dayData]),
              }
            } else return d
          })
        } else {
          data.push({
            month: currMonth,
            items: [dayData],
          })
        }
      })

      // sort per month desc - and sort nested items by date descending
      data = data
        .map(monthData => {
          return {
            items: monthData.items.sort((a, b) => +a.day - +b.day),
            month: monthData.month,
          }
        })
        .sort((a, b) => b.month - a.month)

      content = data.map(monthData => (
        <div key={`frg_${monthData.month}`} className="month-wrapper">
          <div key={`det_${monthData.month}`} className="month-block no-select">
            <div key={`rot_${monthData.month}`} className="rotated">
              {DisplayService.displayMonthFromIndex(monthData.month)}
            </div>
          </div>
          <div
            key={`wrap_${monthData.month}`}
            className="list-wrapper no-select"
          >
            {monthData.items.map(item => (
              <Link
                key={`wrap_${item.day}`}
                className="info-box"
                to={`/day/${item.day}`}
                title={DisplayService.getFormattedDateFromKey(item.day)}
                id={`Day${item.day}`}
              >
                <div key={`wrapi_${item.day}`} className="inner">
                  <div key={`wrapd_${item.day}`} className="date">
                    {DisplayService.getFormattedDayMonthFromString(item.day)}
                  </div>
                  <div key={`wrapc1_${item.day}`}>
                    <span key={`wrapc2_${item.day}`}>{(item.image_ct ? item.image_ct : '-')}</span>
                    <span key={`wrapc3_${item.day}`}>
                      <img src={cameraLogo} alt="images" />
                    </span>
                  </div>
                  <div key={`wrapv1_${item.day}`}>
                    <span key={`wrapv2_${item.day}`}>{(item.video_ct ? item.video_ct : '-')}</span>
                    <span key={`wrapv3_${item.day}`}>
                      <img src={videoLogo} alt="videos" />
                    </span>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ))
    }

    return (
      <div>
        <button
          className="year-block"
          onClick={this.toggleYear}
        >
          <span className="year-display">{this.props.data.year}</span>
          <span className="year-count">
            {(this.props.data.image_ct ? this.props.data.image_ct : '-')}
          </span>
          <img src={cameraLogo} alt="images" />
          <span className="year-count">
            {(this.props.data.video_ct ? this.props.data.video_ct : '-')}
          </span>
          <img src={videoLogo} alt="images" />
        </button>
        <div>{content}</div>
      </div>
    )
  }
}

export default CalendarYear
