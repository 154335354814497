import React, { Component } from "react"
import AppStore from "../services/AppStore"
import MediaService from "../services/MediaService"
import CalendarService from "../services/CalendarService"
import CalendarYear from "./calendar-year"
import loadingIcon from "../images/loader.svg"
import "./latest.css"

class Calendar extends Component {
  constructor(props) {
    super()
    this._isMounted = false

    this.state = {
      loading: true,
      yearList: [],
    }
  }

  async getYearList(yearList) {
    this.buckets = []
    try {
      this.buckets = JSON.parse(this.props.bucketSelection)
    } catch (e) {
      console.error(e)
    }

    return await CalendarService.getYearList(yearList, this.buckets)
  }

  async componentDidMount() {
    this._isMounted = true
    let yearList = await MediaService.getYears()
    let dataList = await this.getYearList(yearList)

    if (this._isMounted) {
      this.setState({
        loading: false,
        yearList: dataList
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  setScrollToVisitedYear() {
    const dateStr = AppStore.get("CALENDAR-DAY-PAGE-TRACKING")
    if (dateStr) {
      AppStore.unset("CALENDAR-DAY-PAGE-TRACKING")
      setTimeout(() => {
        const el = document.getElementById(`Day${dateStr}`)
        if (!el) return
        el.scrollIntoView({ behavior: "smooth", block: "center" })
        }, 500)
    }
  }

  async componentDidUpdate(prevState) {
    let yearList = await MediaService.getYears()
    yearList = await this.getYearList(yearList)
    if (JSON.stringify(this.state.yearList) !== JSON.stringify(yearList)) {
      this.setState({
        yearList,
      })
    }
    this.setScrollToVisitedYear()
  }

  render() {
    if (this.state.loading)
      return (
        <div className="loader">
          <img src={loadingIcon} alt="loading" />
        </div>
      )

    if (this.state.yearList.length === 0)
      return <div className="no-item">No item to display</div>

    return (
      <div className="calendar-wrapper">
        {this.state.yearList.map(yearData => (
          <CalendarYear
            key={`year-${yearData.year}`}
            data={yearData}
            bucketSelection={this.props.bucketSelection}
          />
        ))}
      </div>
    )
  }
}

export default Calendar
