import React, { Component } from "react"
import Calendar from "../components/calendar"
import BucketSelector from '../components/bucket-selector'
import BucketService from '../services/BucketService'
import CookieService from '../services/CookieService'
import Layout from "../components/layout"
import SEO from "../components/seo"

import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from '../config'

Amplify.configure(config)


class CalendarPage extends Component {
  constructor (props) {
    super(props)
    this._isMounted = false

    this.state = { buckets: JSON.stringify([]) }
    
    this.handleBucketChange = this.handleBucketChange.bind(this)
  }

  async componentDidMount () {
    this._isMounted = true
    const buckets = await BucketService.getList()
    const cookies = await CookieService.set() // eslint-disable-line no-unused-vars
    if (this._isMounted) {
      this.setState({
        buckets: JSON.stringify(buckets)
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
 }

  handleBucketChange (evt) {
    let buckets = JSON.parse(this.state.buckets)
    buckets = buckets.map(bucket => {
      if (bucket.name === evt.target.dataset.name) return { ...bucket, selected: !evt.target.classList.contains('selected') }
      return bucket
    })
    BucketService.setList(buckets)
    this.setState({ buckets: JSON.stringify(buckets) })
}

  render () {
    return   <Layout menu="CALENDAR">
      <SEO title="Calendar" />
      <h1>Calendrier</h1>
      <Calendar bucketSelection={this.state.buckets} />
      <BucketSelector buckets={this.state.buckets} handleBucketChange={this.handleBucketChange} />
    </Layout>
  }
}

export default withAuthenticator(CalendarPage, {
  includeGreetings: false
 })
